<template>
    <div class="col-1-1 editable">
        <div class="order-line clearfix" :class="{ highlighted: highlighted}">
            <div class="col-1-1">
                <div class="head">
                    <div class="name">{{title}}</div>
                </div>
            </div>

            <div class="info-line col-1-1 flex align-center">
                <div class="col-2-12 md-col-4-12 xs-col-1-1">
                    <figure class="image flex justify-center">
                        <img :src="img" :alt="title">
                    </figure>
                </div>

                <div class="col-1-12 md-col-4-12 sm-col-2-12 xs-col-4-12">
                    <div class="key">{{amountLabel}}</div>
                </div>
                
                <div class="col-2-12 md-col-4-12 sm-col-6-12 xs-col-8-12">
                    <div class="val">
                        <Counter 
                            @amountUpdated="updateAmount($event)"
                            :quantity="quantity"
                            :minQuantity="minQuantity" 
                            :maxQuantity="maxQuantity"
                        />
                        <div v-if="showCounterWarning" style="color: red; font-size: 1.2rem; margin-left: 3rem;">Add at least 1 item</div>
                    </div>
                </div>

                <div class="col-3-12 md-col-6-12 sm-col-1-1">
                    <div v-if="productHasCoupon" class="coupon-code">
                        <div
                            class="form-row flex flex-column">
                            <label for="voucher">Coupon code *</label>
                            <input 
                                type="text" 
                                id="voucher"
                                v-model.trim="couponCode"
                                @input="updateCoupon()"
                                :class="{error: $v.couponCode.$error}" 
                            />
                            <div v-if="$v.couponCode.$dirty || showErrorMessage">
                                <p class="error" v-if="!$v.couponCode.required">Coupon code {{isRequired}}</p>
                            </div>
                        </div>
                        <ul v-if="errors" class="failed-message">
                            <li v-for="(error, index) in errors" :key="'error' + index">{{error}}</li>
                        </ul>
                    </div>
                </div>

                <div class="col-4-12 md-col-6-12 sm-col-1-1">
                    <div v-if="isLoading" class="small-loader"><LoadingSpinner /></div>
                    <div v-else class="buttons flex justify-end xs-justify-space-between">
                        <button v-if="quantity" @click="removeProductFromOrder" class="btn btn-primary danger-outline small">{{remove}}</button>
                        <button v-else @click="addProductToOrder" class="btn btn-primary alternative small" :class="{'light-grey': amount === 0}">{{orderProductButton}}</button>
                    </div>
                </div>
            </div>
        </div>                            
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import Counter from '../components/ui/Counter.vue';
import LoadingSpinner from '../components/ui/LoadingSpinner.vue';

export default {
    name: 'OrderLine',  
    components: { Counter, LoadingSpinner },
    props: {
        id: Number,
        img: String,
        title: String,
        quantity: Number,
        minQuantity: Number,
        maxQuantity: Number,
        highlighted: Boolean,
        errors: Array
    },
    data() {
        return {
            isLoading: false,
            amount: 0,
            couponCode: '',
            showCounterWarning: false,
            showErrorMessage: false
        }
    },
    methods: {
        updateAmount(data) {
            if (this.showCounterWarning) {
                this.showCounterWarning = false;
            }
            this.amount = data.amount;
            this.$emit('quantityUpdated', {id: this.id, amount: data.amount, action: data.action});
        },
        addProductToOrder() {
            if (this.amount === 0) {
                this.showCounterWarning = true;
            } else {
                this.$emit('newProductAddedToOrder', {id: this.id, quantity: this.amount, couponCode: this.couponCode});     
            }
        },
        removeProductFromOrder() {
            this.$emit('removedFromOrder', {id: this.id, quantity: this.amount});
        },
        updateCoupon() {
            this.$v.couponCode.$touch();
            this.$emit('couponUpdated', {id: this.id, couponCode: this.couponCode});
        }
    },
    created() {
        this.amount = this.quantity;
    },
    validations: {couponCode: { required }},
    computed: {
        ...mapGetters([
            // Settings
            'productHasCoupon',
            // Texts
            'isRequired',
            'orderProductButton',
            'remove',
        ]),
        ...mapGetters({
            // Texts
            amountLabel: 'amount'
        })
    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.order-line {
    padding: 3rem 0;
    border-bottom: 1px solid $grey-light-1;

    &.highlighted {
        border: 2px solid $grey-light-1;
        border-radius: 0.8rem;
        margin-top: 1rem;
    }
    
    .image img {
        width: 100%;
        max-width: 13rem;
        display: block;
    }

        .head {
            align-items: center;
            margin-bottom: .5rem;
            
            .name {
                margin-left: 6rem;
                font-size: 2.4rem;
            }
        }
    .info {
        flex-grow: 1;

        .info-line {
            font-size: 1.6rem;
            line-height: normal;
            padding: .2rem 0;
            align-items: center;
        }
    }

    .small-loader {
        transform: scale(0.5);
        .loading-spinner {
            padding: 0;
        }
    }

    .buttons .btn:first-child {
        margin-right: 1rem;
    }
}

.coupon-code {
    margin-bottom: 3rem;

    label {
         margin-bottom: 1rem;
        font-size: 1.6rem;
        color: $grey;
    }

    input[type="text"] {
        font-family: 'Roboto Condensed', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        background-color: $grey-light-7;
        border: 1px solid $grey-light-6;
        border-radius: 0.8rem;
        width: 100%;
        padding: 15px;
    }

    input[type="text"].error {
        background-color: $grey-light-7;
           border: 1px solid red;
    }

    .error {
        color: red;
        margin: 0 0 1rem 0;
        font-size: 1.2rem;
    }
}

.failed-message {
    margin: 0;
    padding: 2rem 0;
    list-style: none;
    font-size: 1.4rem;
    color: red;
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .coupon-code {
        margin-left: 5rem;
        margin-top: 3rem;
    }

    .buttons {
        margin-right: 5rem;
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 810px) {
    .order-line .head {          
        .name {
            margin-left: 1rem;
        }
    }
    .coupon-code {
        margin-left: 0rem;
        margin-top: 3rem;
    }

    .buttons {
        margin-right: 0rem;
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 500px) {
    .order-line .image {          
        margin-bottom: 3rem;
    }
}
</style>