<template>
  <section class="main-content-section">
    <div class="grid grid-pad">
        <div class="col-1-1">
            <div v-if="programStep !== 1">It is no longer possible to add or modify orders</div>
            
            <div v-else class="orderstatus-summary textpage clear">
                <div class="my-order" id="my-order">
                    <div class="row clearfix">
                        <div class="col-1-1">
                            <h2 class="page-title"><strong>{{myOrder}}:</strong> {{order.number}}</h2>
                        </div>
                        <div class="col-3-4 xs-col-1-1">
                            <strong>{{lastUpdated}}</strong> {{orderDate}}
                        </div>
                        <div class="col-1-4 xs-col-1-1 align-right">
                            <strong>{{total}}</strong>
                        </div>
                    </div>

                    <div class="row clearfix" id="current-order">
                        <OrderLine
                            v-for="line in orderlines"
                            :key="line.id"
                            :id="line.id"
                            :img="line.imageUrl" 
                            :title="line[`name${selectedLanguage}`]" 
                            :quantity="line.quantity"
                            :minQuantity="1"
                            :maxQuantity="getMaxQuantity(line[`name${selectedLanguage}`])" 
                            :highlighted="true"
                            @showModal="noItemsSelected = $event" 
                            @quantityUpdated="updateQuantity($event)"
                            @removedFromOrder="removeProductFromOrder($event)"
                            @couponUpdated="updateCoupon($event)"
                            :errors="line.errors"
                        />
                    </div>

                    <div class="clearfix" id="order-summary">
                        <div class="col-1-1 no-p">
                            <div class="info-line">
                                <div class="key">{{totalAmountOfProducts}}</div>
                                <div class="val" id="sumproductcount">{{ totalAmount }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix" id="extra-order">
                        <div class="col-1-1">
                            <h2 class="page-title sub">{{addMoreProducts}}</h2>
                        </div>
                        <div v-for="product in productsNotOnOrder" :key="product.id">
                            <OrderLine
                                v-if="product.inStock === 1 && !product.isOnOrder"
                                @newProductAddedToOrder="addProductToOrder($event)"
                                @showModal="noItemsSelected = $event"
                                :id="product.id"  
                                :img="product.imageUrl" 
                                :title="product[`name${selectedLanguage}`]" 
                                :quantity="product.quantity"
                                :maxQuantity="product.maxQuantity"
                            />
                        </div>    
                    </div>
                </div>

                <ul v-if="showErrorMessage" class="failed-message">
                    <li v-for="(error, index) in errorMessages" :key="'error' + index">{{error}}</li>
                </ul>

                <div class="clearfix">
                    <div v-if="isLoading" class="small-loader"><LoadingSpinner /></div>
                    <div v-else class="col-1-1 final-actions">
                        <router-link :to="`/my-order/${this.$route.params.orderId}`" class="btn btn-primary small">{{discardChanges}}</router-link>
                        <button @click="updateOrder" :disabled="!hasMadeChanges || !updatedOrderIsValid" class="btn btn-primary alternative small">{{saveChangesAndUpdateOrder}}</button>
                    </div>
                </div>

                <div class="info-wrap-store" v-if="order">
                    <h2 class="title">{{selectedStoreLabel}}</h2>
                    <div class="wrap">
                        <div class="store-info">
                            <div><strong>{{ order.store.name }}</strong></div>
                            <div v-if="selectedStoreData">{{selectedStoreData.address}}, {{selectedStoreData.zipCode}}, {{selectedStoreData.city}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <WarningMessageModal 
        v-show="noItemsSelected" 
        @closeModal="noItemsSelected = $event"
        :title="youHaveNotSelectedAnyProducts"
        :text="addAtLeastOneItemToYourShoppingList" 
    />
    <WarningMessageModal 
        v-show="hasExistingOrder" 
        @closeModal="hasExistingOrder = $event"
        :title="youAlreadyHaveAnOrder"
        :text="addItemsToCurrentOrder" 
    />
</section>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderLine from '@/components/OrderLine';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import agent from '@/api/agent';

export default {
    name: 'EditOrder',
    components: { OrderLine, WarningMessageModal, LoadingSpinner },    
    data() {
        return {
            isLoading: false,
            orderlines: [],
            productsNotOnOrder: [],
            totalAmount: 0,
            noItemsSelected: false,
            hasExistingOrder: false,
            hasMadeChanges: false,
            updatedOrderIsValid: true,
            showErrorMessage: false,
            errorMessages: [],
        }
    },
    methods: {
        setDocumentTitle() {
            document.title = this.changeMyOrder + this.metaTitle;
        },
        getMaxQuantity(name) {
            return this.$store.getters.getProductByName(name).maxQuantity;
        },
        updateQuantity({id, amount, action}) {
            if (this.showErrorMessage) {
                this.showErrorMessage = false;
            }
            this.hasMadeChanges = true;
            const idx = this.orderlines.findIndex(x => x.id === id);        
            this.orderlines[idx].quantity = amount;
            if (action === 'increase') {
                ++this.totalAmount;
            }
            if (action === 'decrease') {
                --this.totalAmount;
            }
            if (this.productHasCoupon) {
                if (!this.orderlines[idx].coupon.length) {
                    this.updatedOrderIsValid = false;
                } else {
                    this.updatedOrderIsValid = true;
                }
            }
        },
        getTotalQuantity() {
            const quantities = this.order.lines.map(line => line.quantity);
            return quantities.length > 1 ? quantities.reduce((a, b) => a + b) : quantities[0];
        },
        fetchOrder() {
            this.$store.dispatch('fetchOrder', this.$route.params.orderId);
        },
        initOrderLines() {
            this.orderlines = this.order.lines.map(x => ({...x, isOnOrder: true, coupon: '', errors: []}));
            this.productsNotOnOrder = this.products.map(x => ({...x, isOnOrder: false, quantity: 0}));
            this.orderlines.forEach(x => {
                this.productsNotOnOrder.forEach(p => {
                    if (x.id === p.id) {
                        p.isOnOrder = true;
                    }
                })
            });
        },
        updateCoupon({id, couponCode}) {
            if (this.showErrorMessage) {
                this.showErrorMessage = false;
            }
            const idx = this.orderlines.findIndex(x => x.id === id);
            this.orderlines[idx].coupon = couponCode;
            if (!couponCode.length) {
                this.updatedOrderIsValid = false;
            } else {
                this.updatedOrderIsValid = true;
            }
        },
        addProductToOrder({id, quantity, couponCode}) {
            if (this.orderlines.length === 0) {
                this.totalAmount = 0;
            }
            if (quantity !== 0) {
                this.hasMadeChanges = true;
                const addedProduct = this.productsNotOnOrder.find(x => x.id === id);
                addedProduct.isOnOrder = true;
                addedProduct.quantity = quantity;
                addedProduct.coupon = couponCode;
                this.orderlines.push(addedProduct);
                this.totalAmount = this.totalAmount + quantity;
                const productNotOnOrderIdx = this.productsNotOnOrder.findIndex(x => x.id === id);
                this.productsNotOnOrder[productNotOnOrderIdx].isOnOrder = true;
            }
        },
        async removeProductFromOrder({id, quantity}) {
            this.hasMadeChanges = true;
            const removedProductIdx = this.productsNotOnOrder.findIndex(p => p.id === id);
            this.totalAmount = this.totalAmount - quantity;
            this.productsNotOnOrder[removedProductIdx].isOnOrder = false;
            this.productsNotOnOrder[removedProductIdx].quantity = 0;
            this.orderlines = this.orderlines.filter(x => x.id !== id);
        },
        async updateOrder() {
            if (this.showErrorMessage) {
                this.showErrorMessage = false;
            }
            const updatedProducts = this.orderlines.map(x => ({id: x.id, quantity: x.quantity, coupon: x.coupon}));

            const updatedOrder = {
                orderId: this.$route.params.orderId,
                products: updatedProducts,
            };
            
            if (this.productHasCoupon) {
                updatedOrder.products.forEach(p => {
                    if (!p.coupon.length) {
                        this.updatedOrderIsValid = false;
                    }
                });
            }

            if (!this.updatedOrderIsValid) {
                this.errorMessages = [this.$store.getters.updatedOrderNotValid];
                this.showErrorMessage = true;
            } else {
                try {
                    this.isLoading = true;
                    const updatedOrderId = await agent.order.update(updatedOrder.orderId, updatedOrder.products);
                    this.isLoading = false;
                    if (updatedOrderId) {
                        this.$router.push(`/order-changed-confirmation?orderId=${updatedOrderId}`);
                    }
                } catch (error) {
                    this.isLoading = false;

                    const getErrorTranslation = (label) => {
                        const texts = this.$store.state.texts.texts;
                        if (texts) {
                            const translations = texts.find(text => text.label === label);
                            return translations[`language${this.selectedLanguage}`];
                        }
                        return null;
                    };

                    if (error.response.data.detail) {
                        const detail = error.response.data.detail;

                        for (const product in detail) {
                            const indexParsedFromObjKey = parseInt(product.split('[')[1][0]);                 
                            detail[product].forEach(err => {
                                this.orderlines[indexParsedFromObjKey].errors = [];
                                const translatedError = getErrorTranslation(err);
                                if (translatedError) {
                                    this.orderlines[indexParsedFromObjKey].errors.push(translatedError);
                                } else {
                                    this.orderlines[indexParsedFromObjKey].errors.push(err);
                                }
                            });
                        }
                        this.showErrorMessage = true;
                    } else if (error.response.data) {
                        this.errorMessages = [this.$store.getters.anErrorOccured];
                        this.showErrorMessage = true;
                        console.error(error.response.data);
                    } else if (error.response) {
                        this.errorMessages = [this.$store.getters.anErrorOccured];
                        this.showErrorMessage = true;
                        console.error(error.response);
                    } else {
                        this.errorMessages = [this.$store.getters.anErrorOccured];
                        this.showErrorMessage = true;
                        console.error(error);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'programStep',
            'productHasCoupon',
            // Texts
            'myOrder',
            'changeMyOrder',
            'lastUpdated',
            'totalAmountOfProducts',
            'total',
            'amountLabel',
            'addMoreProducts',
            'youHaveNotSelectedAnyProducts',
            'addAtLeastOneItemToYourShoppingList',
            'invalidSpecialCharacters',
            'discardChanges',
            'saveChangesAndUpdateOrder',
            'youAlreadyHaveAnOrder',
            'addItemsToCurrentOrder'
        ]),
        ...mapGetters({
            order: 'getOrder',
            products: 'getProducts',
            selectedStoreLabel: 'selectedStore',
            orderDate: 'formattedOrderUpdatedAtDate',
        }),
        selectedStoreData() {
            return this.$store.getters.storeByName(this.order.store.name);
        },
    },
    created() {
        this.setDocumentTitle();
        if (this.$route.query.showExistingOrderMsg) {
            this.fetchOrder();
            this.hasExistingOrder = true;
        }
        if (this.order === null) {
            this.fetchOrder();
        }
        this.initOrderLines();
    },
    mounted() {
        this.totalAmount = this.getTotalQuantity();
        if (this.productHasCoupon) {
            this.updatedOrderIsValid = false;
        }
    },
    updated() {
        this.setDocumentTitle();
    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.orderstatus-summary {
    margin-top: 3rem;
    margin-bottom: 10rem;
    
    .page-title {
        font-size: 3.2rem;
        font-weight: 300;
        text-align: left;

        &.sub {
            margin-top: 4rem;
        }
    }

    .new-order {
        margin-top: 0;
    }
}

.textpage {
    border-radius: 0.8rem;
    box-shadow: $shadow-2;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
}

.align-right {
    text-align: right;
}

#order-summary {
    padding: 2rem 1rem 2rem 1rem;
    
    .info-line {
        border-top: 3px solid $color-primary;
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0;

        .val {
            margin-left: 1rem;
            font-weight: bold;
        }
    }
}

.final-actions {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    margin: 2rem 0;
    background-color: $grey-light-1;
    border-radius: 1rem;

    .btn:first-child {
        margin-right: 1rem;
    }
}

.info-wrap-store {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-light-8;
    background-color: $white;
    border-radius: 12px;
    padding: 3rem;
    margin: 0 0 4rem 0;
    
    .title {
        font-size: 2.4rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }
}

.failed-message {
    margin: 0;
    padding: 2rem 0;
    list-style: none;
    font-size: 1.4rem;
    color: red;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    .textpage {
        padding: 6rem 1rem;
    }
}

@media only screen and (max-width: 400px) {
    .info-wrap-store {
        text-align: center;
    }
} 
</style>